import React from 'react';
import Layout from '../components/layout';
import NavigationButton from '../components/navigation-button';

const NotFoundPage = () => (
  <Layout>
    <h1>404 | NOT FOUND</h1>
    <div className="stripe-hr thin" />
    <p>
      In web development (coding for the internet),
      a 404 error means that we
      couldn&#39;t find what you are looking for.
    </p>
    <p>
      You just hit a route that doesn&#39;t exist.
      If you think we made a mistake and there should be a page here,
      send an email to contact@codechampionship.com
    </p>
    <div className="stripe-hr" />
    <div className="two-wide-container">
      <div>
        <div className="pricing-panel">
          <div className="panel-title">
            Coders
          </div>
          <div className="panel-body">
            <p>
              Join our fun, competitive, and friendly virtual
              coding tournaments for grades 3 to 9.
            </p>
            <NavigationButton
              path="/coders/"
              text="Learn More"
            />
          </div>
        </div>
      </div>
      <div className="pricing-panel">
        <div className="panel-title">
          Educators
        </div>
        <div className="panel-body">
          <p>Learn how to run Code Championship Tournaments for your students.</p>
          <NavigationButton
            path="/educators/"
            text="Learn More"
          />
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
